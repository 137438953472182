<template>
  <v-dialog v-model="show" max-width="640px" width="100%" scrollable>
    <v-card class="add-url-dialog">

      <v-card-title>ADD TEXT</v-card-title>

      <v-card-text class="a-card-text">

        <v-alert :value="errors != null" transition="scale-transition" color="primary" dark>
          <ul><li v-for="e in errors">{{ e }}</li></ul>
        </v-alert>

        <div class="a-row">
          <v-text-field label="TITLE" v-model="title" hint="あとから検索する用途なので適当でOKですが必須。" persistent-hint clearable />
        </div>
        <div class="a-row">
          <v-textarea
            label="TEXT"
            v-model="text"
            hint="テキストをペーストしてください。改行などは適当で可。"
            persistent-hint
            clearable
          />
        </div>

      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed @click="cancelButtonClicked"><v-icon>cancel</v-icon>CANCEL</v-btn>
        <v-btn depressed color="primary" @click="addButtonClicked('ja')" class="add-button"><v-icon>add</v-icon>ADD (ja)</v-btn>
        <v-btn depressed color="primary" @click="addButtonClicked('en')" class="add-button"><v-icon>add</v-icon>ADD (en)</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import Common from './common.js'

export default {
  data() {
    return {
      show: false,
      errors: false,
      title: null,
      text: null
    }
  },
  methods: {
    showDialog() {
      this.errors = null
      this.title = null
      this.text = null
      this.show = true
    },
    addButtonClicked(lang) {
      this.postText(lang)
    },
    cancelButtonClicked() {
      this.show = false
    },
    postText(lang) {
      let params = {
        url: location.href,
        source_type: 1,
        source_text: this.text,
        title: this.title,
        lang: lang
      }
      this.$root.showActivityView()
      Common.axios(document).post('/console/crawls', params).then(response => {
        this.$root.hideActivityView()
        this.$emit('crawl-did-add')
        this.show = false
      }).catch(error => {
        console.log(error)
        this.errors = error.response.data.errors
        this.$root.hideActivityView()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.add-url-dialog {
  .a-card-text {
    padding: 0 24px 32px;
    .a-row {
      margin: 16px 0 0;
    }
    .add-button {
      width: 128px;
    }
  }
}
</style>
