<template>
  <div class="page">

    <v-navigation-drawer class="drawer" dark permanent app width="320">
      <v-list>


        <v-list-item :key="logo" @click="itemClicked({ path: '/searches' })" class="geb-logo-item">
          <v-list-item-content class="geb-logo-content">
            <div>
              <img class="geb-logo" src="../res/logo.png" alt="分解くん" />
            </div>
          </v-list-item-content>
        </v-list-item>

        <template v-for="item in items" >

          <v-divider v-if="item.divider" />



          <v-list-item :key="item.title" @click="itemClicked(item)">
            <v-list-item-icon v-if="item.icon">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-html="item.title" />
            </v-list-item-content>
          </v-list-item>


        </template>
      </v-list>
    </v-navigation-drawer>

    <div class="page-content">
      <slot />
    </div>

    <password-dialog ref="aPasswordDialog" />

  </div>
</template>

<script>
import Common from './common.js'
import PasswordDialog from './password_dialog.vue'

export default {
  components: {
    'password-dialog': PasswordDialog
  },
  computed: {
    items() {
      let items = [
        /* { title: '<h1>good-eye border</h1>', icon: null, path: '/searches', divider: false }, */
        { title: 'Search', icon: 'search', path: '/searches', divider: false },
        { title: 'Crawl', icon: 'pool', path: '/crawls', divider: false },
        { title: 'Dictionary', icon: 'menu_book', path: '/dictionaries', divider: false }
      ]
      if (Common.role() == 'admin') {
        items.push({ title: 'Users', icon: 'people', path: '/users', divider: true })
      }
      items.push({ title: `<h4>${Common.email()}</h4>`, icon: null, path: null, divider: true })
      items.push({ title: 'Change password', icon: 'password', path: '/password', divider: false })
      items.push({ title: 'Sign out', icon: 'logout', path: '/sign_out', divider: false })
      return items
    }
  },
  methods: {
    itemClicked(item) {
      if (!item.path || this.$route.path == item.path) {
        return
      }
      if (item.path == '/password') {
        this.updatePassword()
        return
      }
      if (item.path == '/sign_out') {
        this.signOut()
        return
      }
      this.$router.push(item.path)
    },
    updatePassword() {
      this.$refs.aPasswordDialog.showDialog()
    },
    signOut() {
      Common.saveEmail('')
      Common.saveAccessToken('')
      Common.saveUid('')
      Common.saveRole('')
      Common.saveClient('')
      Common.axios(document).delete('/auth/sign_out').then(response => {
        location.reload()
      }).catch(error => {
        console.log(error)
        location.reload()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  width: 100%;
  min-width: 1024px;

  .drawer {
    width: 320px;
    /* background-color: #FC0079; /* #E91E63; */
    background-color: black;

    .geb-logo-item {
      width: 100%;
      .geb-logo-content {
        width: 100%;
        .geb-logo {
          width: 80%;
        }
      }
    }
  }

  .page-content {
    margin: 0 0 0 320px;
  }
}
</style>
