<template>
  <v-dialog v-model="show" :width="dialogWidth" persistent>
    <v-card class="message-dialog">

      <v-card-title class="a-title">{{ title }}</v-card-title>

      <v-card-text>
        <p v-html="message" />
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed @click="cancelButtonClicked" v-if="cancel">CANCEL</v-btn>
        <v-btn depressed @click="okButtonClicked" class="ok-button" color="#E91E63" dark>OK</v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
export default {
  props: [
    'width'
  ],
  data() {
    return {
      show: false,
      title: 'title',
      message: 'message',
      tag: null,
      cancel: false,
      object: null
    }
  },
  computed: {
    dialogWidth() {
      if (this.width) {
        return this.width
      }
      return '320px'
    }
  },
  methods: {
    showDialog(title, message, tag, cancel, object) {
      this.title = title
      this.message = message
      this.tag = tag
      this.cancel = cancel
      this.object = object
      this.show = true
    },
    okButtonClicked() {
      this.$emit('ok-button-clicked', this.tag, this.object)
      this.show = false
    },
    cancelButtonClicked() {
      this.show = false
    }
  }
}
</script>

<style scoped>
.message-dialog {
  .a-title {
    color: #E91E63;
  }
  p {
    white-space: pre-line;
  }
}
</style>
