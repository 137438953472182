<template>
  <page>
    <div class="crawls">


      <div class="a-header">
        <h1>Crawl</h1>

        <v-btn class="a-header-button" depressed color="#E91E63" dark @click="reloadButtonClicked">
          <v-icon>refresh</v-icon>
        </v-btn>

        <v-btn class="a-header-button" depressed color="#E91E63" dark @click="addButtonClicked">
          <v-icon>add</v-icon>URL
        </v-btn>

        <v-btn class="a-header-button" depressed color="#E91E63" dark @click="addTextButtonClicked">
          <v-icon>add</v-icon>TEXT
        </v-btn>

        <div class="a-text-field">
          <v-text-field v-model="q" label="Search Title or URL" dense hide-details clearable @keydown.enter="keyDownEnter" />
        </div>

        <v-btn
          class="a-header-button"
          depressed color="#E91E63"
          dark
          @click="searchButtonClicked"
          :style="{ opacity: (q && 0 < q.length ? 1.0 : 0.5) }"
        >
          <v-icon>search</v-icon>
        </v-btn>

        <v-spacer />

        <div v-if="pagination">
          <v-pagination v-model="page" :length="pagination.total_pages" total-visible="6" />
        </div>
      </div>


      <div class="a-crawls-container" v-if="crawls">
        <div class="a-crawl" v-for="crawl, i in crawls" :key="crawl.id">
          <div class="a-col a-checkbox">
            <v-checkbox :value="selectedCrawlIds.includes(crawl.id)" @click="checkboxClicked(crawl)" :disabled="crawl.state != 3" />
          </div>
          <div class="a-col a-texts">
            <a :href="crawl.source_type == 0 ? crawl.url : ''" target="_blank">
              <h3>{{ crawl.title }}</h3>
              <div class="geb-row">
                <h4>{{ crawl.lang.toUpperCase() }}</h4>
                <h5>{{ crawl.source_type == 0 ? crawl.url : 'TEXT' }}</h5>
              </div>
            </a>
          </div>
          <div class="a-col w96">
            <p>{{ crawl.id }}</p>
          </div>
          <div class="a-col w128">
            <p>{{ stateText(crawl.state) }}</p>
          </div>
          <div class="a-col w168">
            <p>{{ formatDate(crawl.created_at) }}</p>
            <p>{{ formatDate(crawl.updated_at) }}</p>
          </div>
          <div class="a-col w208">
            <v-btn depressed small color="#E91E63" dark @click="csvButtonClicked(crawl)" :disabled="crawl.state != 3">
              <v-icon>file_download</v-icon>CSV
            </v-btn>
            <v-btn depressed small color="#E91E63" dark @click="textButtonClicked(crawl)" :disabled="crawl.state != 3">
              <v-icon>file_download</v-icon>TEXT
            </v-btn>
            <v-btn icon color="#E91E63" dark @click="deleteButtonClicked(crawl)" :disabled="crawl.state != 3">
              <v-icon>delete</v-icon>
            </v-btn>
          </div>
        </div>
      </div>

      <div :class="'a-footer' + (footerVisible ? ' show' : '')">
        <v-btn
          class="a-search-button"
          depressed
          color="#E91E63"
          dark
          @click="clearButtonClicked"
        >
          <v-icon>clear_all</v-icon>Clear
        </v-btn>
        <v-btn
          class="a-search-button"
          depressed
          color="#E91E63"
          dark
          @click="footerCsvButtonClicked"
        >
          <v-icon>file_download</v-icon>CSV
        </v-btn>
        <v-btn
          class="a-search-button"
          depressed
          color="#E91E63"
          dark
          @click="footerTextButtonClicked"
        >
          <v-icon>file_download</v-icon>TEXT
        </v-btn>
      </div>


      <add-url-dialog ref="addUrlDialog" @crawl-did-add="crawlDidAdd" />
      <add-text-dialog ref="addTextDialog" @crawl-did-add="crawlDidAdd" />
      <message-dialog ref="messageDialog" @ok-button-clicked="okButtonClicked" />


    </div>
  </page>
</template>

<script>
import Common from './common.js'
import Page from './page.vue'
import AddUrlDialog from './add_url_dialog.vue'
import AddTextDialog from './add_text_dialog.vue'
import MessageDialog from './message_dialog.vue'

export default {
  components: {
    'page': Page,
    'add-url-dialog': AddUrlDialog,
    'add-text-dialog': AddTextDialog,
    'message-dialog': MessageDialog
  },
  data() {
    return {
      q: null,
      crawls: null,
      pagination: null,
      selectedCrawlIds: []
    }
  },
  computed: {
    page: {
      get() {
        if (!this.pagination) {
          return 1
        }
        return this.pagination.page
      },
      set(val) {
        this.getCrawls(val, null)
      }
    },
    footerVisible() {
      return this.crawls && 0 < this.crawls.length && 0 < this.selectedCrawlIds.length
    }
  },
  methods: {
    stateText(state) {
      return [
        'CREATED',
        'WAITING',
        'RUNNING',
        'SUCCEEDED',
        'FAILED'
      ][state]
    },
    formatDate(date) {
      return date.replace('T', ' ').split('.')[0]
    },
    checkboxClicked(crawl) {
      let index = this.selectedCrawlIds.findIndex(crawlId => crawlId == crawl.id)
      if (0 <= index) {
        this.selectedCrawlIds.splice(index, 1)
      } else {
        this.selectedCrawlIds.push(crawl.id)
      }
    },
    reloadButtonClicked() {
      this.selectedCrawlIds = []
      this.q = null
      this.getCrawls(1)
    },
    addButtonClicked() {
      this.$refs.addUrlDialog.showDialog()
    },
    addTextButtonClicked() {
      this.$refs.addTextDialog.showDialog()
    },
    crawlDidAdd() {
      this.selectedCrawlIds = []
      this.q = null
      this.getCrawls(1)
    },
    keyDownEnter(event) {
      if (event.keyCode !== 13) {
        return
      }
      this.getCrawls(1)
    },
    searchButtonClicked() {
      this.getCrawls(1)
    },
    csvButtonClicked(crawl) {
      this.postCsv([crawl.id])
    },
    textButtonClicked(crawl) {
      this.postText([crawl.id])
    },
    deleteButtonClicked(crawl) {
      this.$refs.messageDialog.showDialog('good-eye border', '削除してよろしいですか？', 1, true, crawl)
    },
    okButtonClicked(tag, object) {
      this.deleteCrawl(object)
    },
    clearButtonClicked() {
      this.selectedCrawlIds = []
    },
    footerCsvButtonClicked(crawl) {
      this.postCsv(this.selectedCrawlIds)
    },
    footerTextButtonClicked(crawl) {
      this.postText(this.selectedCrawlIds)
    },
    getCrawls(page) {
      let params = {
        page: page,
        q: this.q
      }
      this.$root.showActivityView()
      Common.axios(document).get('/console/crawls', { params: params }).then(response => {
        console.log(response)
        this.crawls = response.data.crawls
        this.pagination = response.data.pagination
        this.$root.hideActivityView()
      }).catch(error => {
        console.log(error)
        this.$root.hideActivityView()
      })
    },
    deleteCrawl(crawl) {
      this.$root.showActivityView()
      Common.axios(document).delete(`/console/crawls/${crawl.id}`).then(response => {
        let index = this.selectedCrawlIds.findIndex(selectedId => selectedId == crawl.id)
        if (0 <= index) {
          this.selectedCrawlIds.splice(index, 1)
        }
        let crawlIndex = this.crawls.findIndex(c => c.id == crawl.id)
        if (0 <= crawlIndex) {
          this.crawls.splice(crawlIndex, 1)
        }
        this.$root.hideActivityView()
      }).catch(error => {
        console.log(error)
        this.$root.hideActivityView()
      })
    },
    postCsv(ids) {
      let params = {
        ids: ids
      }
      this.$root.showActivityView()
      Common.axios(document).post('/console/crawls/csv', params).then(response => {
        let filename = response.headers['content-disposition'].match(/filename="(.+)"/)[1]
        Common.createCsv(filename, response.data)
        this.$root.hideActivityView()
      }).catch(error => {
        console.log(error)
        this.$root.hideActivityView()
      })
    },
    postText(ids) {
      let params = {
        ids: ids
      }
      this.$root.showActivityView()
      Common.axios(document).post('/console/crawls/text', params).then(response => {
        let filename = response.headers['content-disposition'].match(/filename="(.+)"/)[1]
        Common.createCsv(filename, response.data)
        this.$root.hideActivityView()
      }).catch(error => {
        console.log(error)
        this.$root.hideActivityView()
      })
    }
  },
  mounted() {
    this.selectedCrawlIds = []
    this.q = null
    this.getCrawls(1)
  }
}
</script>

<style scoped lang="scss">
.crawls {
  position: relative;
  width: 100%;
  height: 100vh;

  .a-header {
    width: 100%;
    height: 80px;
    padding: 16px;
    display: flex;
    align-items: center;

    h1 {
      margin: 0 32px 0 0;
    }
    .a-text-field {
      width: 50%;
      max-width: 640px;
      margin: 0 16px 0 0;
    }
    .a-header-button {
      display: block;
      margin: 0 16px 4px 0;
    }
  }
  .a-crawls-container {
    padding: 0 0 64px;
    width: 100%;
    height: calc(100vh - 80px);
    overflow-y: scroll;

    .a-crawl {
      width: 100%;
      margin: 16px 0;
      padding: 0 16px;
      display: flex;
      align-items: center;

      .a-col {
        padding: 0 16px 0 0;

        &.a-checkbox {
          padding: 0;
          flex: 0 0 44px;
        }
        &.a-texts {
          overflow: hidden;
          flex: 1 1 auto;
        }
        &.w96 {
          width: 96;
        }
        &.w208 {
          padding: 0;
          width: 208px;
          flex: 0 0 208px;
        }
        &.w128 {
          width: 128px;
          flex: 0 0 128px;
        }
        &.w168 {
          width: 168px;
          flex: 0 0 168px;
        }

        a {
          text-decoration: none;
        }

        h3 {
          color: #880E4F;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .geb-row {
          display: flex;
          align-items: center;
          h4 {
            margin: 0 16px 0 0;
            color: #F06292;
          }
          h5 {
            color: #F06292;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-decoration: underline;
          }
        }
        p {
          margin: 0;
          padding: 0;
          color: rgba(0, 0, 0, 0.87);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .a-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 68px;
    padding: 16px;
    background-color: white;

    opacity: 0;
    transition: opacity 0.4s;
    &.show {
      opacity: 1.0;
    }
  }
}
</style>
