<template>
  <page>
    <div class="users">
      <div class="a-header">
        <div class="a-col">
          <h1>Users</h1>
          <v-btn depressed dark color="#E91E63" class="add-button" @click="addButtonClicked"><v-icon>add</v-icon>新規作成</v-btn>
        </div>
        <v-pagination v-model="page" :length="pagination.total_pages" total-visible="6" v-if="pagination" />
      </div>

      <users-table :users="users" @user-clicked="userClicked" />

      <user-dialog ref="aUserDialog" @user-did-update="userDidUpdate" />
      <user-add-dialog ref="aUserAddDialog" @user-did-create="userDidUpdate" />

    </div>
  </page>
</template>

<script>
import Common from './common.js'
import Page from './page.vue'
import UsersTable from './users_table.vue'
import UserDialog from './user_dialog.vue'
import UserAddDialog from './user_add_dialog.vue'

export default {
  components: {
    'page': Page,
    'users-table': UsersTable,
    'user-dialog': UserDialog,
    'user-add-dialog': UserAddDialog
  },
  data() {
    return {
      users: null,
      pagination: null
    }
  },
  computed: {
    page: {
      get() {
        if (!this.pagination) {
          return 1
        }
        return this.pagination.page
      },
      set(val) {
        this.getUsers(val)
      }
    }
  },
  methods: {
    addButtonClicked() {
      this.$refs.aUserAddDialog.showDialog()
    },
    userClicked(user) {
      this.$refs.aUserDialog.showDialog(user)
    },
    userDidUpdate() {
      this.getUsers(this.page)
    },
    getUsers(page) {
      let params = {
        page: page
      }
      this.$root.showActivityView()
      Common.axios(document).get('/console/users', { params: params }).then(response => {

        console.log(response)
        this.users = response.data.users
        this.pagination = response.data.pagination

        this.$root.hideActivityView()
      }).catch(error => {
        console.log(error)
        this.$root.hideActivityView()
      })
    }
  },
  mounted() {
    this.getUsers(1)
  }
}
</script>

<style scoped lang="scss">
.users {
  .a-header {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .a-col {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h1 {
        margin: 0 32px 0 0;
      }
    }
  }
}
</style>
