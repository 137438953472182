<template>
  <page>
    <div class="searches">


      <div class="a-header">
        <h1>Search</h1>
        <div class="a-text-field">
          <v-text-field
            v-model="q"
            label="Search Google"
            dense
            hide-details
            clearable
            autofocus
            @keydown.enter="keyDownEnter"
          />
        </div>

        <v-btn
          class="a-search-button"
          depressed
          color="#E91E63"
          dark
          @click="searchButtonClicked"
          :style="{ opacity: (q && 0 < q.length ? 1.0 : 0.5) }"
        >
          <v-icon>search</v-icon>Search
        </v-btn>

        <v-btn
          class="a-search-button"
          depressed
          @click="openButtonClicked"
          :style="{ opacity: (q && 0 < q.length ? 1.0 : 0.5) }"
        >
          <v-icon>open_in_browser</v-icon>Web
        </v-btn>
      </div>


      <div class="a-result-container" v-if="search && search.search_results && 0 < search.search_results.length">
        <div class="a-result" v-for="result in search.search_results" :key="result.id">
          <div class="a-checkbox">
            <v-checkbox :value="selectedResults.includes(result.id)" @click="checkboxClicked(result)" />
          </div>
          <a class="a-content" :href="result.link" target="_blank">
            <h3 v-html="result.html_title" />
            <h5 v-html="result.html_formatted_url" />
            <p v-html="result.html_snippet" />
          </a>
        </div>
        <div class="a-result">
          <div class="a-checkbox">
          </div>
          <div class="a-content">
            <a @click="moreButtonClicked">
              <h3>More results</h3>
            </a>
          </div>
        </div>
      </div>


      <div :class="'a-footer' + (footerVisible ? ' show' : '')">
        <v-btn
          class="a-search-button"
          depressed
          color="#E91E63"
          dark
          @click="crawlButtonClicked('ja')"
          :style="{ opacity: (q && 0 < q.length ? 1.0 : 0.5) }"
        >
          <v-icon>pool</v-icon>ADD TO CRAWL(JA)
        </v-btn>
        <v-btn
          class="a-search-button"
          depressed
          color="#E91E63"
          dark
          @click="crawlButtonClicked('en')"
          :style="{ opacity: (q && 0 < q.length ? 1.0 : 0.5) }"
        >
          <v-icon>pool</v-icon>ADD TO CRAWL(EN)
        </v-btn>
      </div>


    </div>
  </page>
</template>

<script>
import Common from './common.js'
import Page from './page.vue'

export default {
  components: {
    'page': Page,
  },
  data() {
    return {
      q: null,
      search: null,
      selectedResults: []
    }
  },
  computed: {
    footerVisible() {
      return this.search
          && this.search.search_results
          && 0 < this.search.search_results.length
    }
  },
  methods: {
    keyDownEnter(event) {
      if (event.keyCode !== 13) {
        return
      }
      this.postSearches()
    },
    searchButtonClicked() {
      this.postSearches()
    },
    openButtonClicked() {
      if (!this.q || this.q.length <= 0) {
        return
      }
      window.open(`https://www.google.com/search?q=${this.q}`)
    },
    checkboxClicked(result) {
      let index = this.selectedResults.findIndex(r => r == result.id)
      if (0 <= index) {
        this.selectedResults.splice(index, 1)
      } else {
        this.selectedResults.push(result.id)
      }
    },
    moreButtonClicked() {
      this.moreSearch()
    },
    crawlButtonClicked(lang) {
      this.postCrawls(lang)
    },
    postSearches() {
      if (!this.q || this.q.length <= 0) {
        return
      }
      let params = {
        q: this.q
      }
      this.$root.showActivityView()
      Common.axios(document).post('/console/searches', params).then(response => {
        this.search = response.data.search
        this.selectedResults = []
        setTimeout(() => this.getSearch(), 1000)
      }).catch(error => {
        console.log(error)
        this.$root.hideActivityView()
      })
    },
    getSearch() {
      if (!this.search) {
        return
      }
      this.$root.showActivityView()
      Common.axios(document).get(`/console/searches/${this.search.id}`).then(response => {
        this.search = response.data.search
        if ([3, 4].includes(this.search.state)) {
          this.$root.hideActivityView()
          return
        }
        setTimeout(() => this.getSearch(), 1000)
      }).catch(error => {
        console.log(error)
        this.$root.hideActivityView()
      })
    },
    moreSearch() {
      if (!this.search || !this.search.search_results) {
        return
      }
      let params = {
        search: {
          start: this.search.start + this.search.search_results.length
        }
      }
      this.$root.showActivityView()
      Common.axios(document).patch(`/console/searches/${this.search.id}`, params).then(response => {
        setTimeout(() => this.getSearch(), 1000)
      }).catch(error => {
        console.log(error)
        this.$root.hideActivityView()
      })
    },
    postCrawls(lang) {
      let urls = this.search.search_results.filter(sr => this.selectedResults.includes(sr.id)).map(sr => sr.link)
      let params = {
        urls: urls,
        lang: lang
      }
      this.$root.showActivityView()
      Common.axios(document).post('/console/crawls/urls', params).then(response => {
        this.$root.hideActivityView()
        this.$router.push('/crawls')
      }).catch(error => {
        console.log(error)
        this.$root.hideActivityView()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.searches {
  position: relative;
  width: 100%;
  height: 100vh;

  .a-header {
    width: 100%;
    height: 80px;
    padding: 16px;
    display: flex;
    align-items: center;

    h1 {
      margin: 0 32px 0 0;
    }
    .a-text-field {
      width: 50%;
      max-width: 640px;
      margin: 0 16px 0 0;
    }
    .a-search-button {
      display: block;
      margin: 0 16px 4px 0;
    }
  }
  .a-result-container {
    padding: 0 0 64px;
    width: 100%;
    height: calc(100vh - 80px);
    overflow-y: scroll;

    .a-result {
      padding: 16px;
      display: flex;
      align-items: center;

      .a-checkbox {
        width: 44px;
      }

      .a-content {
        display: block;
        text-decoration: none;
        overflow: hidden;

        h3 {
          color: #880E4F;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        h5 {
          color: #F06292;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-decoration: underline;
        }
        p {
          margin: 0;
          padding: 0;
          color: rgba(0, 0, 0, 0.87);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .a-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 68px;
    padding: 16px;
    background-color: white;

    opacity: 0;
    transition: opacity 0.4s;
    &.show {
      opacity: 1.0;
    }
  }
}
</style>
