<template>
  <v-dialog v-model="show" max-width="640px" width="100%">
    <v-card class="add-url-dialog">

      <v-card-title>ADD URL</v-card-title>

      <v-card-text>

        <v-alert :value="errors != null" transition="scale-transition" color="primary" dark>
          <ul><li v-for="e in errors">{{ e }}</li></ul>
        </v-alert>

        <div class="a-row">
          <v-text-field label="URL" v-model="url" hide-details clearable />
        </div>

      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed @click="cancelButtonClicked"><v-icon>cancel</v-icon>CANCEL</v-btn>
        <v-btn depressed color="primary" @click="addButtonClicked('ja')" class="add-button"><v-icon>add</v-icon>ADD (JA)</v-btn>
        <v-btn depressed color="primary" @click="addButtonClicked('en')" class="add-button"><v-icon>add</v-icon>ADD (EN)</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import Common from './common.js'

export default {
  data() {
    return {
      show: false,
      errors: false,
      url: null
    }
  },
  methods: {
    showDialog() {
      this.errors = null
      this.url = null
      this.show = true
    },
    addButtonClicked(lang) {
      this.postUrl(lang)
    },
    cancelButtonClicked() {
      this.show = false
    },
    postUrl(lang) {
      let params = {
        crawl: {
          source_type: 0,
          url: this.url,
          lang: lang
        }
      }
      this.$root.showActivityView()
      Common.axios(document).post('/console/crawls', params).then(response => {
        this.$root.hideActivityView()
        this.$emit('crawl-did-add')
        this.show = false
      }).catch(error => {
        console.log(error)
        this.errors = error.response.data.errors
        this.$root.hideActivityView()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.add-url-dialog {
  .a-row {
    margin: 16px 0 32px;
  }
  .add-button {
    width: 128px;
  }
}
</style>
