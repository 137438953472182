<template>
  <page>
    <h1>Hello, world</h1>
  </page>
</template>

<script>
import Page from './page.vue'

export default {
  components: {
    'page': Page
  }
}
</script>
