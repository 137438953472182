<template>
  <div class="forgot-password">
    <div class="forgot-password-container">
      <div class="forgot-password-content">
        <h1>Forgot your password?</h1>
        <div class="a-row">
          <p>Enter your email address and we'll send you a link to reset your password.</p>
        </div>

        <v-form @submit="onSubmit">

          <div class="a-row">
            <v-alert :value="errors">
              <ul><li v-for="e in errors">{{ e }}</li></ul>
            </v-alert>
          </div>

          <div class="a-row">
            <v-text-field
              label="EMAIL"
              v-model="email"
              clearable
              dark
              hide-details
            />
          </div>

          <div class="a-row">
            <v-btn large depressed type="submit" class="forgot-password-button">Submit</v-btn>
          </div>

        </v-form>

        <div>
          <a @click="$router.push('/sign_in')">Back to Sign In</a>
        </div>
      </div>

      <message-dialog ref="aMessageDialog" @ok-button-clicked="okButtonClicked" />

    </div>
  </div>
</template>

<script>
import Common from './common.js'
import MessageDialog from './message_dialog.vue'

export default {
  components: {
    'message-dialog': MessageDialog
  },
  data() {
    return {
      email: Common.email(),
      errors: null
    }
  },
  methods: {
    okButtonClicked() {
      this.$router.push('/')
    },
    onSubmit(evt) {
      evt.preventDefault()

      this.$root.showActivityView()
      Common.axios(document).post('/auth/password', {
        email: this.email,
        redirect_url: location.origin + '/reset_password'
      }).then(response => {
        console.log(response)
        this.$refs.aMessageDialog.showDialog('good-eye border', 'Password Reset Email Sent')
        this.$root.hideActivityView()
      }).catch(error => {
        console.log(error)
        console.log(error.response)
        this.errors = error.response.data.errors
        this.password = ""
        this.$root.hideActivityView()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.forgot-password {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #E91E63;
  color: white;

  .forgot-password-container {
    width: 65%;
    margin: 0 0 64px;
    min-width: 360px;

    .forgot-password-content {
      width: 100%;
      max-width: 360px;
      padding: 16px;

      h1 {
        white-space: nowrap;
      }

      .a-row {
        margin: 32px 0;
        .forgot-password-button {
          width: 200px;
          color: #E91E63;
        }
      }

      a {
        color: white;
        &:hover, &:visited {
          color: white;
        }
      }
    }
  }
}
</style>
