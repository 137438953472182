import Axios from 'axios'

export default {
  axios(document) {
    let csrfToken = document.getElementsByName('csrf-token')[0].getAttribute('content')
    return Axios.create({
      headers: {
        'X-CSRF-TOKEN': csrfToken,
        'Accept': 'application/json',
        'access-token': this.accessToken(),
        'client': this.client(),
        'uid': this.uid()
      }
    })
  },
  email() {
    return localStorage.getItem('email')
  },
  saveEmail(email) {
    localStorage.setItem('email', email)
  },
  accessToken() {
    return localStorage.getItem('access-token')
  },
  saveAccessToken(accessToken) {
    localStorage.setItem('access-token', accessToken)
  },
  uid() {
    return localStorage.getItem('uid')
  },
  saveUid(uid) {
    localStorage.setItem('uid', uid)
  },
  role() {
    return localStorage.getItem('role')
  },
  saveRole(role) {
    localStorage.setItem('role', role)
  },
  client() {
    return localStorage.getItem('client')
  },
  saveClient(client) {
    localStorage.setItem('client', client)
  },


  createCsv(filename, data) {
    let bom = new Uint8Array([0xEF, 0xBB, 0xBF])
    let blob = new Blob([bom, data], {type: 'text/csv'})
    let url  = (window.URL || window.webkitURL).createObjectURL(blob)
    let link = document.createElement('a')
    document.body.appendChild(link)
    link.href = url
    link.download = filename
    link.click();
    (window.URL || window.webkitURL).revokeObjectURL(url)
    document.body.removeChild(link)
  }
}
