<template>
  <v-dialog v-model="show" max-width="640px" width="100%">
    <v-card class="add-dictionary-dialog">

      <v-card-title>ADD DICTIONARY</v-card-title>

      <v-card-text class="a-card-text">

        <v-alert :value="errors != null" transition="scale-transition" color="primary" dark>
          <ul><li v-for="e in errors">{{ e }}</li></ul>
        </v-alert>

        <div class="a-row">
          <v-text-field label="Title" v-model="title" hide-details clearable />
        </div>

        <div class="a-row">
          <app-csv-uploader @csv-did-select="csvDidSelect" />
        </div>

      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed @click="cancelButtonClicked"><v-icon>cancel</v-icon>CANCEL</v-btn>
        <v-btn depressed color="primary" @click="addButtonClicked" class="add-button"><v-icon>add</v-icon>ADD</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import Common from './common.js'
import AppCsvUploader from './app_csv_uploader.vue'

export default {
  components: {
    'app-csv-uploader': AppCsvUploader
  },
  data() {
    return {
      show: false,
      errors: false,
      title: null,
      csv: null
    }
  },
  methods: {
    showDialog() {
      this.title = null
      this.csv = null
      this.errors = null
      this.show = true
    },
    addButtonClicked() {
      this.postDictionary()
    },
    cancelButtonClicked() {
      this.show = false
    },
    csvDidSelect(csv) {
      this.csv = csv
    },
    postDictionary() {
      console.log('postDictionary')

      if (!this.title || this.title.length <= 0) {
        this.errors = ['Titleを入力してください']
        return
      }
      if (!this.csv) {
        this.errors = ['CSVファイルを入力してください']
        return
      }
      let formData = new FormData()
      formData.append('title', this.title)
      formData.append('csv', this.csv)
      let config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      this.$root.showActivityView()
      Common.axios(document).post('/console/dictionaries', formData, config).then(response => {
        console.log(response)
        this.$emit('dictionary-did-add')
        setTimeout(() => {
          this.show = false
          this.$root.hideActivityView()
        }, 400)
      }).catch(error => {
        console.log(error)
        this.$root.hideActivityView()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.add-dictionary-dialog {
  .a-card-text {
    padding: 0 16px;
    .a-row {
      margin: 16px 0;
    }
    .add-button {
      width: 96px;
    }
  }
}
</style>
