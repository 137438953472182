<template>
  <div
    class="app-csv-uploader"
    @dragover="onDragOver($event)"
    @drop="onDrop($event)"
  >

    <div class="a-row">
      <p>{{ text }}</p>
      <v-btn depressed @click="fileButtonClicked">ファイル選択</v-btn>
    </div>

    <input
      ref="pgInputFile"
      type="file"
      name="image"
      id="image"
      accept="text/csv, text/plain"
      style="display: none"
      @change="fileChanged($event)"
    />

  </div>
</template>

<script>
export default {
  data() {
    return {
      csv: null
    }
  },
  computed: {
    text() {
      if (this.csv) {
        return this.csv.name
      }
      return 'CSVファイルをドロップしてください'
    }
  },
  watch: {
    csv(newVal, oldVal) {
      this.$emit('csv-did-select', this.csv)
    }
  },
  methods: {
    fileButtonClicked() {
      this.$refs.pgInputFile.click()
    },
    fileChanged(e) {
      e.preventDefault()
      let file = e.target.files[0]
      if (!['text/csv', 'text/plain'].includes(file.type)) {
        this.csv = null
        return
      }
      this.csv = file
    },
    onDragOver(e) {
      e.preventDefault()
    },
    onDrop(e) {
      e.preventDefault()
      if (e.dataTransfer.files.length <= 0) {
        return
      }
      let file = e.dataTransfer.files[0]
      if (!['text/csv', 'text/plain'].includes(file.type)) {
        this.csv = null
        return
      }
      this.csv = file
    }
  }
}
</script>

<style scoped lang="scss">
.app-csv-uploader {
  padding: 16px;
  border-radius: 4px;
  border: solid thin rgba(0, 0, 0, 0.42);

  .a-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      margin: 0;
    }
  }
}
</style>
