<template>
  <page>
    <div class="dictionaries">


      <div class="a-header">
        <h1>Dictionary</h1>
        <v-btn class="a-header-button" depressed color="#E91E63" dark @click="addButtonClicked"><v-icon>add</v-icon>CSV</v-btn>
        <h4>BUILD: {{ buildText }}</h4>
        <v-btn class="a-header-button" depressed color="#E91E63" dark @click="reloadButtonClicked" v-if="buttonState == 'reload'">
          <v-icon>refresh</v-icon>
        </v-btn>
        <v-btn class="a-header-button" depressed color="#E91E63" dark @click="runButtonClicked" v-if="buttonState == 'run'">
          <v-icon>directions_run</v-icon>RUN
        </v-btn>
      </div>


      <table v-if="dictionaries">
        <thead>
          <tr>
            <th>ID</th>
            <th>STATE</th>
            <th>TITLE</th>
            <th>FILENAME</th>
            <th>CREATED AT</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr class="a-dictionary" v-for="dictionary, i in dictionaries" :key="dictionary.id">
            <td>{{ dictionary.id }}</td>
            <td>{{ stateText(dictionary.state) }}</td>
            <td>{{ dictionary.title }}</td>
            <td><a :href="dictionary.csv_url">{{ dictionary.csv_filename }}</a></td>
            <td>{{ formatDate(dictionary.created_at) }}</td>
            <td>
              <v-btn icon color="#E91E63" @click="deleteButtonClicked(dictionary)"><v-icon>delete</v-icon></v-btn>
            </td>
          </tr>
        </tbody>
      </table>


      <add-dictionary-dialog ref="addDictionaryDialog" @dictonary-did-add="dictionaryDidAdd" />
      <message-dialog ref="messageDialog" @ok-button-clicked="okButtonClicked" />


    </div>
  </page>
</template>

<script>
import Common from './common.js'
import Page from './page.vue'
import AddDictionaryDialog from './add_dictionary_dialog.vue'
import MessageDialog from './message_dialog.vue'

export default {
  components: {
    'page': Page,
    'add-dictionary-dialog': AddDictionaryDialog,
    'message-dialog': MessageDialog
  },
  data() {
    return {
      buildDictionary: null,
      dictionaries: null
    }
  },
  computed: {
    buildStateText() {
      if (!this.buildDictionary) {
        return 'UNKNOWN'
      }
      return [
        'CREATED',
        'RUNNING',
        'SUCCEEDED',
        'FAILED'
      ][this.buildDictionary.state]
    },
    buildText() {
      if (this.buildDictionary) {
        return `STATE: ${this.buildStateText }, LAST UPDATE: ${this.formatDate(this.buildDictionary.updated_at)}`
      }
      return 'UNKNOWN'
    },
    buttonState() {
      if (this.buildStateText == 'CREATED' || this.buildStateText == 'RUNNING') {
        return 'reload'
      }
      return 'run'
    }
  },
  methods: {
    stateText(state) {
      return [
        'CREATED',
        'ENABLED',
        'DISABLED',
        'INVALID'
      ][state]
    },
    formatDate(date) {
      return date.replace('T', ' ').split('.')[0]
    },
    addButtonClicked() {
      this.$refs.addDictionaryDialog.showDialog()
    },
    dictionaryDidAdd() {
      this.getDictionaries()
    },
    reloadButtonClicked() {
      this.getDictionaries()
    },
    runButtonClicked() {
      this.postBuildDictionary()
    },
    deleteButtonClicked(dictionary) {
      this.$refs.messageDialog.showDialog('good-eye border', '削除してよろしいですか？', 1, true, dictionary)
    },
    okButtonClicked(tag, object) {
      this.deleteDictionary(object)
    },
    getDictionaries() {
      this.$root.showActivityView()
      Common.axios(document).get('/console/dictionaries').then(response => {
        console.log(response)
        this.buildDictionary = response.data.build_dictionary
        this.dictionaries = response.data.dictionaries
        this.$root.hideActivityView()
      }).catch(error => {
        console.log(error)
        this.$root.hideActivityView()
      })
    },
    postBuildDictionary() {
      this.$root.showActivityView()
      Common.axios(document).post('/console/build_dictionaries').then(response => {
        this.getDictionaries()
      }).catch(error => {
        console.log(error)
        this.$root.hideActivityView()
      })
    },
    deleteDictionary(dictionary) {
      this.$root.showActivityView()
      Common.axios(document).delete(`/console/dictionaries/${dictionary.id}`).then(response => {
        this.getDictionaries()
      }).catch(error => {
        console.log(error)
        this.$root.hideActivityView()
      })
    }
  },
  mounted() {
    this.getDictionaries()
  }
}
</script>

<style scoped lang="scss">
.dictionaries {
  position: relative;
  width: 100%;
  height: 100vh;

  .a-header {
    width: 100%;
    height: 80px;
    padding: 16px;
    display: flex;
    align-items: center;

    h1 {
      margin: 0 32px 0 0;
    }
    h4 {
      margin: 0 16px 0 0;
    }
    .a-header-button {
      display: block;
      margin: 0 16px 4px 0;
    }
  }

  table {
    position: relative;
    width: 100%;
    border-spacing: 0;
    tr {
      border-bottom: solid 1px rgba(0, 0, 0, 0.2);
      &:hover {
        background-color: #FCE4EC;
      }
    }
    th {
      position: sticky;
      top: 0;
      text-align: left;
      padding: 8px 16px;
      font-size: 14px;
      border-bottom: solid 1px rgba(0, 0, 0, 0.2);
      background-color: white;

    }
    td {
      padding: 0 16px;
      font-size: 14px;
      white-space: pre-line;
      border-bottom: solid 1px rgba(0, 0, 0, 0.2);
    }
  }
}
</style>
